<template>
<div>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>

            <b-row>
                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Sınıf/Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Konu</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Video Grup</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Ev Ödevi</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTest" :options="tests" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2 mt-2">
                    <b-button variant="success" block @click="getTestData()">FİLTRELE</b-button>
                </b-col>
                <b-col cols="12" md="3" class="mb-md-0 mb-2 mt-2">
                    <b-button variant="primary" block @click="downloadTestData()">İNDİR</b-button>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>

    <b-card no-body>
        <b-row style="padding:10px">
            <b-col>
                <label>Öğretmen</label>
                <b-form-input id="filter-input" v-model="filter" type="search" class="col-md-12" placeholder="Öğretmen Ara"></b-form-input>
            </b-col>
            <b-col>
                <label>Ödev</label>
                <b-form-input id="filter-input" v-model="filter2" type="search" class="col-md-12" placeholder="Ödev Ara"></b-form-input>
            </b-col>
            <b-col>
                <label>Başlangıç Tarihi</label>
                <flat-pickr v-model="startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'en'}" />
            </b-col>
            <b-col>
                <label>Bitiş Tarihi</label>
                <flat-pickr v-model="endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'en'}" />
            </b-col>
        </b-row>

        <b-table :items="filteredItems" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <template #cell(status)="data">
                <b-badge pill :variant="getExamStatus(data.item.type)">
                    {{ getExamStatusText(data.item.type) }}
                </b-badge>
            </template>

            <template #cell(name)="data">
                <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="data.toggleDetails">{{data.item.name }}</b-button>
            </template>

            <template #cell(exitCount)="data">
                <b-button block variant="flat-primary" class="font-weight-bolder" @click="sendNotification(data.item)">Öğrencilere Bildirim Gönder</b-button>
            </template>

            <template #cell(teacher)="data">
                <span class="font-weight-bolder mb-12">
                    {{data.item.teacher!=null?data.item.teacher.firstName+' '+data.item.teacher.lastName:'-'}}
                </span>
            </template>
            <template #row-details="data">
                <b-card>
                    <b-card-body>
                        
                        <b-tabs align="center">
                            <b-tab active title="Ödevi Yapan Öğrenciler">
                                <b-table :items="data.item.user.filter(x=>x.isSuccess==true)" responsive :fields="userSuccessFields" class="mb-0" v-if="data.item.type=='Test'">
                                    <template #cell(idNumber)="data">
                                        <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                    </template>
                                </b-table>
                                <b-table v-else :items="data.item.user.filter(x=>x.isSuccess==true)" :fields="userVideoFields" responsive class="mb-0">
                                    <template #cell(idNumber)="data">
                                        <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                    </template>
                                    <template #cell(correct)="data">
                                        <b-progress key="success" animated :value="data.item.correct" variant="success" class="progress-bar-success" />
                                    </template>
                                    <template #cell(userPoint)="data">
                                        <b>{{new Date(data.item.userPoint * 1000).toISOString().substring(14, 19)}}</b>
                                    </template>
                                </b-table>
                            </b-tab>
                            <b-tab title="Ödevi Yapmayan Öğrenciler">
                                <b-table :items="data.item.user.filter(x=>x.isSuccess==false)" responsive :fields="userSuccessFields" class="mb-0" v-if="data.item.type=='Test'">
                                    <template #cell(idNumber)="data">
                                        <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                    </template>
                                </b-table>
                                <b-table v-else :items="data.item.user.filter(x=>x.isSuccess==false)" :fields="userVideoFields" responsive class="mb-0">
                                    <template #cell(idNumber)="data">
                                        <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                    </template>
                                    <template #cell(correct)="data">
                                        <b-progress key="success" animated :value="data.item.correct" variant="success" class="progress-bar-success" />
                                    </template>
                                    <template #cell(userPoint)="data">
                                        <b>{{new Date(data.item.userPoint * 1000).toISOString().substring(14, 19)}}</b>
                                    </template>
                                </b-table>
                            </b-tab>
                        </b-tabs>

                    </b-card-body>
                </b-card>
            </template>

        </b-table>

    </b-card>

</div>
</template>

<style>
.col-form-label-sm {
    padding-top: 10px !important;

}

.form-row {
    padding-left: 25px !important;
}
</style>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BTabs,
    BTab
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import XLSX from 'xlsx';
export default {
    components: {
        flatPickr,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay,
        BTabs,
        BTab
    },
    data() {
        return {
            loading: false,
            startDate: '',
            endDate: '',
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'teacher', label: 'Öğretmen' },
                // { key: 'code', label: 'Ödev Kodu' },
                { key: 'name', label: 'Ödev Adı' },
                { key: 'definition', label: 'Açıklama' },
                { key: 'startDate', label: 'Başlama Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'status', label: 'Tip' },
                { key: 'exitCount', label: 'İşlemler' }
            ],
            status: [
                { key: 2, label: 'Devam Ediyor' },
                { key: 4, label: 'Sonuçlar İşleniyor' },
                { key: 5, label: 'Sonuçlandı' },
            ],
            userSuccessFields: [
                { key: 'idNumber', label: 'Öğrenci Tc' },
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
                { key: 'class', label: 'Şube' },
                { key: 'correct', label: 'Doğru Sayısı' },
                { key: 'empty', label: 'Boş Sayısı' },
                { key: 'wrong', label: 'Yanlış Sayısı' },
                { key: 'userPoint', label: 'Öğrencinin Puanı' }
            ],
            userVideoFields:[
            { key: 'idNumber', label: 'Öğrenci Tc' },
            { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
            { key: 'class', label: 'Şube' },
            { key: 'correct', label: 'Video İzleme Yüzdesi' },
            { key: 'userPoint', label: 'İzlediği Dakika' },
            ],
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            users: [],

            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            filter: '',
            filter2: '',

            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 5
            },
            ort: {
                totalOrt: 0,
                correct: 0,
                wrong: 0,
                empty: 0,
                point: 0
            }
        }
    },
    created() {
        this.getLesson();
    },
    watch: {

        filter: function (val) {

        },
        selectedLesson: function (val) {
            this.getChildLesson();
            this.childClass = [];
            this.filterRequest.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getTests();
        }
    },
    methods: {
        filterTable(row, filter) {

            /*code: (...)
            endDate: (...)
            id: (...)
            name: (...)
            startDate: (...)
            status: (...)
            teacher: Object
            birthDate: (...)
            campaignEmail: (...)
            campaignPhone: (...)
            campaignSms: (...)
            createdAt: (...)
            deletedAt: (...)
            email: (...)
            firstName: (...)
            gender: (...)
            gsmNumber: (...)
            id: (...)
            idNumber: (...)
            isActive: (...)
            isDeleted: (...)
            isValidation: (...)
            lastName: (...)
            originalRef: (...)
            password: (...)
            profileImage: (...)
            updatedAt: (...)
            userType: (...)

             */

        },
        openUserWatch(userId) {
            let routeData = this.$router.resolve({ name: 'watchingUser', params: { uid: userId } });
            window.open(routeData.href, "Öğrenci Hareketleri", 'height=800,width=800');
        },
        removeData(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.loading = true;
                        this.$http.delete("Report/UserTest/" + data.testId + "/" + data.user.id).then(() => {
                                this.loading = false;
                                this.getTestData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Öğrenci Testi Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                this.loading = false;
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        },
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },

        async getTests() {
            var tests = await this.$http.get("Teacher/HomeWorks/" + this.selectedVideoGroup);
            this.tests = tests.data.data;
        },
        async sendNotification(item) {
            //console.log(item);
            var users = item.user.filter(x => x.isSuccess == false);
            var request = users.map(x => x.id);
            var response = await this.$http.post("Report/SendNotificationHomeWork/" + item.id, request);
            //console.log(response);
        },
        async getTestData() {
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                this.loading = true;
                data = data[0];

                this.users = [];
                var request = {
                    lessonId: data.lesson.id,
                    classId: "00000000-0000-0000-0000-000000000000",
                    childLessonId: "00000000-0000-0000-0000-000000000000",
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                    homeWorkId: "00000000-0000-0000-0000-000000000000",
                    status: null
                };

                if (this.selectedChildLesson != null) { request.childLessonId = this.selectedChildLesson; }
                if (this.selectedTopic != null) { request.topicId = this.selectedTopic; }
                if (this.selectedVideoGroup != null) { request.videoGroupId = this.selectedVideoGroup; }
                if (this.selectedTest != null) { request.homeWorkId = this.selectedTest; }

                var lesson = await this.$http.post("Report/HomeWork", request);
                this.users = lesson.data.data;
                this.loading = false;
            }
        },
        async getStudentReport() {
            var students = new Array();
            var ws_data = new Array();
            var homeworksrow = new Array();
            homeworksrow.push([]); // column for student names 
            homeworksrow.push("Şube"); // column for student names 
            
            var filterTestItems=this.filteredItems.filter(x=>x.type=='Test')

            filterTestItems.forEach(homework => {
                homeworksrow.push(homework.name);
            });
            //merge all students
            //for each homework
            filterTestItems.forEach(homework => {
                //for each student
                homework.user.forEach(e => {
                    var times = 0;
                    var userexists = (students.find(function (user) {
                        if (user.id == e.id) return true;
                        else false
                    }));

                    if (userexists) {
                        userexists.times++;
                        /*userexists.homework.push({
                             "lesson":{id:homework.id,name:homework.name},
                             "point":e.userPoint
                        })*/
                    } else {
                        students.push({
                            "id": e.id,
                            "class": e.class,
                            "idNumber": e.idNumber,
                            "fullName": e.fullName,
                            "times": times,
                            /*homework:[
                                {
                                    "lesson":{id:homework.id,name:homework.name},
                                    "point":e.userPoint
                                }
                            ]*/
                        });
                    }
                });
            });

            ws_data.push(homeworksrow);
            students.forEach(student => {
                var row = new Array();
                row.push(student.fullName);
                row.push(student.class);
                //if students homework doesnt exists in homework -- lesson point : 0
                filterTestItems.forEach(homework => {
                    var userexists = (homework.user.find(function (user) {
                        if (user.id == student.id) return true;
                        else false
                    }));
                    if (userexists) {
                        row.push(Math.round(userexists.userPoint * 10) / 10);
                    } else {
                        row.push(0);
                    }
                });

                ws_data.push(row);
            });

            return ws_data;
        },
        async getTeacherReport() {
            var ws_data = new Array();
            var teachers = new Array();
            var headerRow = new Array();
            headerRow.push("Öğretmen Adı")
            headerRow.push("Konu")
            headerRow.push("Sınıflar")
            headerRow.push("Gönderim")
            headerRow.push("Başlangıç Tarihi")
            headerRow.push("Bitiş Tarihi")
            ws_data.push(headerRow)
            var filterTestItems=this.filteredItems;

            filterTestItems.forEach(homework => {
                var teacherName = homework.teacher != null ? homework.teacher.firstName + ' ' + homework.teacher.lastName : '-'
                var check = teachers.filter(x => x == teacherName);
                if (check == null || check.length == 0) {
                    teachers.push(teacherName)
                }
            });

            teachers.forEach(teacher => {
                var videoGroups = new Array();
                var classes = new Array();
                var homeWorks = filterTestItems.filter(filter => {
                    var teacherName = filter.teacher != null ? filter.teacher.firstName + ' ' + filter.teacher.lastName : '-';
                    if (teacherName == teacher)
                        return true;
                })
                homeWorks.forEach(hm => {
                    var videoGroupName = hm.type=='Test'? hm.test.videoGroup.name:hm.video.videoGroup.name;
                    var check = videoGroups.filter(x => x == videoGroupName);
                    if (check == null || check.length == 0) {
                        videoGroups.push(videoGroupName)
                    }
                })

                videoGroups.forEach(vd => {
                    var homeWorksVideogrps = filterTestItems.filter(filter => {
                        var teacherName = filter.teacher != null ? filter.teacher.firstName + ' ' + filter.teacher.lastName : '-';
                        if (teacherName != teacher)
                            return false;
                        var videoGroupName = filter.type=='Test'?filter.test.videoGroup.name : filter.video.videoGroup.name;
                        if (videoGroupName != vd)
                            return false;
                        return true;
                    })

                    var dates = new Array();
                    homeWorksVideogrps.forEach(hmn => {
                        var startDate = hmn.startDate;
                        var endDate = hmn.endDate;
                        var check = dates.filter(x => x.startDate == startDate && x.endDate == endDate);
                        if (check == null || check.length == 0) {
                            dates.push({
                                startDate: startDate,
                                endDate: endDate
                            });
                        }
                    });

                    dates.forEach(dat => {
                        var row = new Array();
                        row.push(teacher);
                        row.push(vd);
                        var homeDateFilter = filterTestItems.filter(filter => {
                            var teacherName = filter.teacher != null ? filter.teacher.firstName + ' ' + filter.teacher.lastName : '-';
                            if (teacherName != teacher)
                                return false;

                            var videoGroupName = filter.type=='Test'?filter.test.videoGroup.name : filter.video.videoGroup.name;
                            if (videoGroupName != vd)
                                return false;
                            if (filter.startDate != dat.startDate && filter.endDate != dat.endDate)
                                return false;
                            return true;
                        });

                        var classes = new Array();
                        homeDateFilter.forEach(hmn => {
                            hmn.user.forEach(us => {
                                var check = classes.filter(x => x == us.class);
                                if (check == null || check.length == 0) {
                                    classes.push(us.class)
                                }
                            })
                        });
                        row.push(classes.join());
                        row.push(homeDateFilter.length + " Adet Ödev Gönderdi");
                        row.push(dat.startDate);
                        row.push(dat.endDate);
                        //console.log(vd, homeDateFilter);
                        ws_data.push(row);
                    })
                });
            })
            return ws_data;
        },

        async getVideoData(){
            var students = new Array();
            var ws_data = new Array();
            var homeworksrow = new Array();
            homeworksrow.push([]); // column for student names 
            homeworksrow.push("Şube"); // column for student names 
            
            var filterTestItems=this.filteredItems.filter(x=>x.type=='Video')

            filterTestItems.forEach(homework => {
                homeworksrow.push(homework.name);
            });
            //merge all students
            //for each homework
            filterTestItems.forEach(homework => {
                //for each student
                homework.user.forEach(e => {
                    var times = 0;
                    var userexists = (students.find(function (user) {
                        if (user.id == e.id) return true;
                        else false
                    }));

                    if (userexists) {
                        userexists.times++;
                        /*userexists.homework.push({
                             "lesson":{id:homework.id,name:homework.name},
                             "point":e.userPoint
                        })*/
                    } else {
                        students.push({
                            "id": e.id,
                            "class": e.class,
                            "idNumber": e.idNumber,
                            "fullName": e.fullName,
                            "times": times,
                            /*homework:[
                                {
                                    "lesson":{id:homework.id,name:homework.name},
                                    "point":e.userPoint
                                }
                            ]*/
                        });
                    }
                });
            });

            ws_data.push(homeworksrow);
            students.forEach(student => {
                var row = new Array();
                row.push(student.fullName);
                row.push(student.class);
                //console.log(student);

                filterTestItems.forEach(homework => {
                    var userexists = (homework.user.find(function (user) {
                        if (user.id == student.id) return true;
                        else false
                    }));
                    if (userexists) {
                        row.push( new Date(userexists.userPoint * 1000).toISOString().substring(14, 19));
                    } else {
                        row.push(0);
                    }
                });

                ws_data.push(row);
            });

            return ws_data;
        },
        async downloadTestData() {

            var ws_data = await this.getStudentReport();
            var ws_teacher_data = await this.getTeacherReport();
            var ws_video_data=await this.getVideoData();
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Ödev",
                Subject: "Ödev",
                Author: "Yes Akademi",
                CreatedDate: new Date()
            };

            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.SheetNames.push("Öğrenci Raporu");
            ws["!cols"] = [{ width: 50 }];;; //student names width
            for (let index = 0; index < 30; index++) {
                ws["!cols"].push({ width: 25 });

            }

            var wsTeacher = XLSX.utils.aoa_to_sheet(ws_teacher_data);
            wb.SheetNames.push("Öğretmen Raporu");

            var wsVideo = XLSX.utils.aoa_to_sheet(ws_video_data);
            wb.SheetNames.push("Video Raporu");

            wb.Sheets["Öğrenci Raporu"] = ws;
            wb.Sheets["Öğretmen Raporu"] = wsTeacher;
            wb.Sheets["Video Raporu"] = wsVideo;
            await XLSX.writeFile(wb, "homeworks.xlsx");
            //console.log(students);
        },
        getExamStatus(status) {
            switch (status) {
                case 'Test':
                    return 'primary'
                case 'Document':
                    return 'info'
                case 'Video':
                    return 'success'
            }
        },

        getExamStatusText(status) {
            switch (status) {
                case 'Test':
                    return "Test"
                case 'Document':
                    return "Döküman"
                case 'Video':
                    return "Video"
            }
        },
        convertToDate(date) {
            var d = date.split(" "); // d-m-y h:i
            var dh = d[1].split(":"); // d-m-y h:i
            var dmy = d[0].split("-"); // d-m-y
            return new Date(dmy[2], parseInt(dmy[1]) - 1, dmy[0], dh[0], dh[1]);
        },
    },
    computed: {
        filteredItems: function (val) {
            var filter = this.filter.toLowerCase();
            var filter2 = this.filter2.toLowerCase();
            return this.users.filter(row => {
                var teachername = " "
                if (row.teacher) {
                    teachername = row.teacher.firstName + " " + row.teacher.lastName;
                }
                var sdate = this.startDate;
                var edate = this.endDate;
                if (sdate == "") sdate = "01-01-1990 00:00"
                if (edate == "") edate = "01-01-2030 00:00"
                var startDate = this.convertToDate(sdate);

                if (startDate.getTime() > this.convertToDate(row.startDate).getTime()) {
                    return false;
                }
                var endDate = this.convertToDate(edate);
                if (endDate.getTime() < this.convertToDate(row.endDate).getTime()) {
                    return false;
                }
                if (!teachername.toLowerCase().includes(filter) || (
                        !row.name.toLowerCase().includes(filter2) &&
                        !row.code.toLowerCase().includes(filter2))) {
                    return false;
                }

                return true;

            });

        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-knowledge-base.scss';

</style>
